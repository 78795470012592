@import 'components/global.scss';

main > header {
  border-bottom: 1px solid #EAEDF3;
  position: relative;
  width: auto;
  z-index: 3;
  background: white;
  padding: $scale1;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;

  h1 {

    font-size: $scale;

  }

  .btn {

    position: absolute;
    top: 50%;
    right: $scale2;
    z-index: 100000;
    font-size: $scale;
    transform: translateY(-50%);
    padding: 0.3em 1em 0.3em $scale4;

  }

  @media (max-width: $mobile){

    color: white;
    text-align: center;
    background: $c-dark;
    border: none;
    text-align: left;
    padding-left: $scale5;

  }
}
