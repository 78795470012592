@import 'components/global.scss';

.subnav {

  border-bottom: 1px solid #EAEDF3;
  overflow: hidden;
  padding: $scale1 $scale2 0;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;
  background: white;
  text-align: center;

  a {

    color: $c-text;
    display: inline-block;
    padding-bottom: $scale;
    margin-right: $scale2;
    text-decoration: none;

    &.active {

      border-bottom: 2px solid $c-blue;

    }
  }

  @media (min-width: $mobile){

    //border-top: 1px solid $c-border;

  }
}
