@import 'components/global.scss';

.notification {

  position: fixed;
  left: 0;
  right: 0;
  color: white;
  z-index: 999999;
  text-align: center;
  padding: 0.75em $scale;

  &.show {

    top: 0;
    opacity: 0.9;
    transition: all 0.45s ease-in-out;

  }

  &.hide {

    top: -$scale3;
    opacity: 0;
    transition: all 0.25s ease-in-out;

  }

  &.success {

    background-color: $c-green;

  }

  &.warning {

    background-color: $c-orange;

  }

  &.error {

    background-color: $c-red;

  }

  .btn-close-notification {

    position: absolute;
    top: 50%;
    right: $scale2;
    opacity: 0.7;
    width: 1.5em;
    height: 1.5em;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transition: all 0.2s ease-in-out;

    &:hover {

      opacity: 1;

    }
  }
}
