@import 'components/global.scss';

.modal {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 9999999;
  background: black;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;

  header {

    margin-bottom: $scale;

  }

  .progress {

    padding: $scale2;
    margin-top: -$scale3;
    margin-left: -$scale2;
    margin-right: -$scale2;
    margin-bottom: $scale2;
    background-color: #FBFBFD;

    h2 {

      margin-bottom: 0;

    }
  }

  &.show {

    display: block;
    opacity: 0;

    .modal-content {

      top: -50%;

    }
  }

  &.hide {

    display: none;
    opacity: 0;

    .modal-content {

      top: 0%;

    }
  }

  &.animateIn {

    opacity: 1;

    .modal-content {

      top: $scale4;

    }
  }

  &.animateOut {

    opacity: 0;

    .modal-content {

      top: 0;

    }
  }

  &.loading .modal-content {

    min-height: $scale12;

  }

  .scroller {

    float: left;
    width: 100%;
    overflow-y: auto;
    max-height: $scale9;
    margin-bottom: $scale1;

    .list {

      margin-top: 0;

    }
  }

  h2 {

    width: 100%;
    text-transform: uppercase;
    font-size: 0.8em;
    margin: 0 0 $scale/2 0;
    font-weight: 600;
    color: lighten($c-text, 22%);

  }

  h3 {

    font-size: 0.8em;
    color: lighten($c-text, 12%);

  }

  .list {

    font-size: 0.9em;
    margin-top: $scale;
    column-count: 2;
    column-gap: 1em;

    li {

      padding-left: 0;
      margin-bottom: $scale/2;

      &:before {

        display: none;

      }

    }
  }

  .box {

    clear: both;
    font-size: 0.9em;
    padding: $scale;
    margin-bottom: $scale1;
    border: 1px solid $c-border;
    background-color: #FBFBFD;

  }

  &.min-height {

    .modal-content {

      min-height: $scale14;

    }
  }

  .modal-content {

    position: absolute;
    top: 0;
    left: 50%;
    width: 94%;
    top: $scale4;
    text-align: left;
    max-height: $scale17;
    overflow-y: auto;
    background-color: white;
    transform: translateX(-50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-out;

  }

  .message {

    font-size: 0.9em;
    padding: $scale2 $scale2;
    margin-bottom: $scale1 !important;

    .btn:last-child {

      float: left;

    }
  }

  .custom {

    font-weight: 700;

  }

  .view {

    &.hide {

      display: none;

    }
  }

  @media (max-width: 299px){

    display: none;

    .btn, .btn-loader {

      width: 100%;

      &:last-child {

        margin-top: $scale/2;

      }
    }
  }

  @media (min-width: 300px){

    .btn, .btn-loader {

      float: left;
      width: 48%;

      &:last-child {

        float: right;

      }
    }
  }

  @media (min-width: 500px){

    .modal-content {

      max-width: $scale15;

    }
  }
}
