@import 'components/global.scss';

.app {

  padding: $scale2;

  @media (min-width: $mobile){

    padding: $scale2;

  }
}
