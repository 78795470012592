@import 'components/global.scss';

nav.sidebar,
nav.mobile-slideout {

  position: fixed;
  width: $scale12;
  height: 100%;
  z-index: 999;
  padding: $scale7 $scale $scale $scale;
  background-color: $c-dark;

  .logo {

    margin-bottom: $scale5;

  }

  h2 {

    color: #797A7F;
    font-size: 1em;
    margin-bottom: 1em;
    padding-left: $scale;

  }

  .nav-links {

    a {

      display: block;
      width: 100%;
      text-decoration: none;
      color: white;
      font-size: 0.9em;
      padding: 0.75em $scale1;

      &.active {

        color: $c-dark;
        border-radius: $radius;
        background-color: $c-yellow;

      }
    }
  }

  footer {

    background-color: transparent;
    padding: $scale;

    .btn-text {

      display: block;
      font-size: $scale-1;
      margin-bottom: $scale;

    }

  }

  .support {

    h2 {

      margin-bottom: 1em;

    }

    a {

      display: block;
      color: white;
      margin-left: $scale1;
      font-size: 0.9em;
      margin-bottom: 0.5em;
      text-decoration: none;
      padding-bottom: 0.25em;

    }
  }

  footer {

    position: absolute;
    bottom: $scale/2;

  }

  @media (min-width: $mobile){

    height: 100%;

  }

  @media (min-width: $mobile){

    .nav-links a {

      width: 100% !important;

    }
  }
}

nav.mobile-slideout {

  @media (min-width: $mobile + 1){

    .btn-togglenav {

      display: none;

    }
  }

  @media (max-width: $mobile){

    position: absolute;
    width: $scale11;
    left: -$scale11;
    height: 100%;
    background: $c-dark;
    transition: all 0.2s ease-in-out;

    .nav-links {

      a {

        width: 100% !important;

      }
    }

    &.open {

      position: fixed;

    }

    .btn-togglenav {

      display: block;
      position: absolute !important;
      top: 1.75em;
      left: 100%;
      width: 1.5em;
      height: 1.5em;
      cursor: pointer;
      margin-left: $scale;

      @media (min-width: $mobile){

        display: none !important;

      }
    }

    &.open {

      left: 0;

      + main {

        opacity: 0.05;

      }
    }
  }
}
