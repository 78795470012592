@import 'components/global.scss';

table.styled {

  width: 100%;
  border-spacing: 0 0.65em;
  border-collapse: separate;
  margin-top: -0.75em;
  margin-bottom: -0.5em;
  min-height: $scale5;

  thead {

    font-weight: 600;

    th {

      text-align: left;
      outline: none;
      color: lighten($c-text, 25%);
      font-size: $scale-1;
      text-transform: uppercase;
      padding: 0 $scale1 $scale/2;

      &:first-child {

        padding-left: 0;

      }
    }

    .sort {

      position: relative;
      cursor: pointer;

      &:after {

        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 1em;
        height: 0.5em;
        opacity: 0.5;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        background-size: contain;

      }
    }

    .asc:after {

      background-image: url("./icons/ico-sort-asc.svg");

    }

    .desc:after {

      background-image: url("./icons/ico-sort-dsc.svg");

    }
  }

  tbody {

    tr {

      background-color: white;

    }

    td {

      white-space: nowrap;
      padding: $scale1;

    }
  }

  .badge {

    margin: 0;

  }

  .cell-title {

    font-weight: 700;
    font-size: $scale !important;

  }

  .cell-label {

    color: darken($c-text, 10%);
    display: none;
    font-weight: 600;
    text-transform: capitalize;

  }

  .cell-row {

    &:nth-child(2){

      font-size: 0.85em;
      margin-top: 0.25em;

    }
  }

  .btn-text {

    color: $c-primary;
    font-size: 0.9em;
    text-decoration: underline;

  }

  .actions {

    text-align: right;

  }

  @media (max-width: $mobile){

    thead {

      display: none;

    }

    tbody {

      tr:first-child td:first-child {

        margin-top: $scale;

      }

      td {

        float: left;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: $scale;

        &:first-child {

          padding-top: $scale1;

        }
      }

      .actions {

        text-align: left;
        margin-bottom: 0;
        padding-bottom: $scale;

      }
    }

    .cell-label {

      display: block;

    }
  }
}

.card header + table.styled {

  margin-top: -$scale3;

}

.card table.styled {

  border-spacing: 0;
  margin-bottom: -$scale2;
  min-height: $scale5;
  margin-top: -$scale1;

  thead {

    th {

      padding: $scale1;
      border-bottom: 1px dotted darken($c-border, 3%);

    }
  }

  th, td {

    &:first-child {

      padding-left: 0;

    }

    &:last-child {

      padding-right: 0;

    }
  }

  tbody {

    tr {

      background-color: transparent;

      &:last-child td {

        border-bottom: 0;

      }
    }
  }

  @media (max-width: $mobile){

    tbody td {

      padding: 0;

    }

    tbody tr {

      td:last-child {

        margin-bottom: $scale;
        padding-bottom: $scale;
        border-bottom: 1px solid $c-border;

      }

      &:last-child td {

        border-bottom: none;
        padding-bottom: 0;

      }
    }
  }

  @media (min-width: $mobile + 1){

    tbody td {

      border-bottom: 1px solid $c-border;

    }
  }
}

.search + table {

  margin-top: 0 !important;

}
