@import 'components/global.scss';

.select {

  position: relative;
  cursor: pointer;
  color: $c-text;
  width: 100%;
  margin-bottom: $scale1;
  overflow: hidden;
  border: 1px solid $c-border;
  background-color: white;

  &.error {

    border: 1px solid $c-red;

    select {

      color: $c-red;

    }

    .error-message {

      margin-bottom: 0;

    }
  }

  &.warning {

    border: 1px solid $c-orange;

    select {

      color: $c-orange;

    }

    .warning-message {

      margin-bottom: -1px;

    }
  }

  &:after {

    content: "";
    position: absolute;
    top: 50%;
    right: $scale;
    width: 0.85em;
    height: 0.5em;
    opacity: 0.35;
    pointer-events: none;
    transform: translateY(-50%);
    background-size: contain;
    background-image: url("./icons/ico-dropdown.svg");

  }

  select {

    width: 120%;
    color: $c-text;
    font-size: $scale;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: transparent;
    outline: none;
    cursor: pointer;
    padding: $scale;

  }

  select::-ms-expand {

    display: none;

  }
}
