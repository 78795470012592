@import 'components/global.scss';

.card {
  border: 1px solid #EAEDF3;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  position: relative;
  padding: $scale2;
  margin-bottom: $scale2;
  background: white;
  overflow: scroll;
  min-height: $scale8;
  z-index: 1;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  header + .loader {

    margin-top: $scale2;

  }

  &.loading {

    min-height: $scale12;

  }

  &:last-of-type {

    margin-bottom: 0;

  }

  &.hide {

    display: none;

  }

  header {

    position: relative;
    overflow: hidden;
    text-align: left;
    color: $c-text;
    padding-bottom: $scale-1;
    margin-bottom: $scale3;
    background-color: white;
    border-bottom: 1px dotted $c-border;

    h1 {

      float: left;
      margin: 0;
      font-size: $scale1;
      font-weight: 600;

    }

    a {

      float: right;
      margin-top: 0.2em;
      font-size: 0.9em;

    }

    &.hide {

      display: none;

    }
  }

  .message:last-of-type {

    margin-bottom: 0;

  }
}
