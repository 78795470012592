@import 'components/global.scss';

form {

  .btn[type="submit"],
  .btn-next-step {

    display: block;
    clear: both;

  }

  input[type=submit], .btn-loader {

    width: 100%;

  }

  > header {

    padding: 0 0 1em 0 !important;
    margin: $scale2 0 $scale;
    border-bottom: 1px dotted $c-border;

    h2 {

      margin-bottom: $scale/3;

    }

    p {

      color: lighten($c-text, 16%);
      font-size: $scale-1;

    }
  }

  .message {

    margin-bottom: $scale1;

  }

  .helper {

    color: darken($c-orange, 15%);
    margin: -0.45em 0 0.75em;
    text-align: left;
    font-size: 0.8em;

  }

  &.loading {

    opacity: 0.5;

  }

  .link {

    display: block;
    margin-bottom: $scale;

  }

  @media (min-width: $mobile){

    &.restrict-width {

      max-width: $scale15;
      margin-left: 0;

    }
  }
}
