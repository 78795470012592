@import 'components/global.scss';

.progress-step-bar {

  list-style: none;

  li {

    display: inline-block;
    width: $scale4;
    height: $scale/3;
    background-color: #E7E6E8;
    margin-right: $scale/2;

    &.complete {

      background-color: $c-green;

    }

    &:last-child {

      margin-right: 0;

    }
  }
}
