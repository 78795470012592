@import 'components/global.scss';

.nav-header {

  overflow: hidden;
  padding: $scale3 $scale3;
  margin-bottom: $scale3;
  background-color: white !important;

  .logo {

    float: left;
    margin: 0;

  }

  nav {

    float: right;
    margin-top: 0.25em;

    a {

      display: inline-block;
      color: $c-text;
      text-decoration: none;
      margin-right: $scale;

      &:last-child {

        margin-right: 0;

      }
    }

    .btn {

      color: white;
      display: inline-block;
      padding: 0.25em 0.5em;
      font-size: $scale !important;

    }
  }

  @media (max-width: 375px){

    padding-top: $scale2;
    padding-bottom: 0;

    .logo {

      width: $scale9;

    }

    nav {

      float: left;
      clear: both;

    }
  }
}
