@import 'components/global.scss';

.message {

  position: relative;
  padding: $scale3;
  overflow: hidden;
  width: 100%;
  margin-bottom: $scale1;

  .ico {

    float: left;
    width: 3em;
    height: 3em;
    margin-right: $scale3;

    &:after {

      width: 50%;
      height: 50%;

    }
  }

  .content {

    color: darken($c-text, 3%);
    width: auto;
    overflow: hidden;

    h1 {

      font-size: $scale1;
      margin-bottom: $scale/2;

    }

    p:last-child {

      margin-bottom: 0;

    }
  }

  .btn {

    color: white !important;
    display: inline-block;
    padding: 0.75em $scale1;

  }

  .btn-close {

    position: absolute;
    top: $scale1;
    right: $scale1;
    opacity: 0.6;
    width: 1.3em;
    height: 1.3em;
    cursor: pointer;
    margin-right: 0;
    background-color: transparent !important;
    transition: all 0.2s ease-in-out;

    .ico {

      width: 100%;
      height: 100%;

    }

    &:hover {

      opacity: 0.9;

    }
  }

  &.info {

    background-color: lighten($c-blue, 44%);

    h1, a {

      color: $c-blue;

    }

    .btn {

      background-color: lighten($c-blue, 8%);

      &:hover {

        background-color: darken($c-blue, 0%);

      }

    }
  }

  &.success {

    background-color: lighten($c-green, 48%);

    h1, a {

      color: $c-green;

    }

    .btn {

      background-color: lighten($c-green, 6%);

      &:hover {

        background-color: darken($c-green, 2%);

      }
    }
  }

  &.warning {

    background-color: lighten($c-orange, 26.5%);

    h1, a {

      color: $c-orange;

    }

    .btn {

      background-color: lighten($c-orange, 2%);

      &:hover {

        background-color: darken($c-orange, 6%);

      }
    }
  }

  &.error {

    background-color: lighten($c-red, 34%);

    h1, a {

      color: lighten($c-red, 10%);

    }

    p {

      color: darken($c-text, 9%);

    }

    .btn {

      background-color: lighten($c-red, 10%);

      &:hover {

        background-color: $c-red;

      }
    }
  }

  @media (max-width: 400px){

    .ico:first-child {

      float: none;
      width: $scale4;
      height: $scale4;
      margin: 0 auto;

    }

    .content {

      float: left;
      margin-top: $scale1;

    }
  }
}
