@import 'components/global.scss';

.stats {

  float: left;
  width: 100%;
  margin-bottom: $scale2;

  .stat {

    width: 32%;
    margin-right: 2%;

    &:last-child {

      margin-right: 0;
      margin-bottom: 0;

    }
  }

  @media (max-width: 350px){

    .stat {

      width: 100%;
      margin-bottom: $scale/2;

    }
  }
}
