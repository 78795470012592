@import 'components/global.scss';

.logo-wrapper {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3.7em;
  border-right: 1px solid $c-border;
  background-color: white;

  .logo {

    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 3px;
    transform: translate(-50%, -50%);

  }
}

.logo {

  display: block;
  width: $scale7;
  height: $scale3;
  margin: 0 auto $scale2;
  outline: none;

  &.active {

    background-color: transparent !important;

  }
}
