@import 'components/global.scss';

html {

  height: 100%;
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;

}

body {

  color: $c-text;
  background-color: $c-bg;
  font-size: 1.4rem;
  font-family: "Source Sans Pro", helvetica, arial, sans-serif;

  @media (min-width: $mobile){

    font-size: 1.5rem;

  }

  @media (min-width: 1200px){

    font-size: 1.6rem;

  }

  &.color-bg {

    background-color: $c-dark;
    background-attachment: fixed;

  }
}

.content {

  width: 100%;
  margin: 0 auto;

}
