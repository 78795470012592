@import 'components/global.scss';
@import 'components/global/animation.scss';

main > .spinner {

  @media (min-width: $mobile + 1){

    margin-left: $scale9;

  }
}

.spinner {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .animation {

    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-color: $c-primary;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;

  }

  .text {

    text-align: center;
    font-size: 0.75em;
    margin-top: $scale;

  }
}

@-webkit-keyframes sk-rotateplane {

  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {

  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
