@import 'components/global.scss';

.stat {

  position: relative;
  float: left;
  color: white;
  text-align: left;
  min-height: $scale7;
  padding: $scale1;
  background-color: $c-blue;
  background-size: 2.5em;
  background-position: top -0.5em right 1em;

  .spinner .animation {

    width: 15px !important;
    height: 15px !important;

  }

  .ico {

    position: absolute;
    top: 50%;
    left: $scale2;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

  }

  .label {

    text-transform: uppercase;
    font-size: 0.85em;

  }

  .value {

    font-size: $scale3;
    font-weight: 600;

  }

  &.up {

    background-image: url('./icons/ico-up.svg');

  }

  &.down {

    background-image: url('./icons/ico-down.svg');

  }

  .change {

    margin-top: $scale;
    padding-top: $scale1;
    font-size: 0.8em;
    text-transform: uppercase;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

  }
}
