@import 'components/global.scss';

.textbox,
.creditcard {

  display: block;
  position: relative;
  width: 100%;
  color: $c-text;
  font-size: $scale;
  padding: $scale;
  margin: 0 0 $scale1;
  background-color: white;
  border: 1px solid $c-border;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;

  &:focus {

    background-color: #fafafa;

  }

  &.success {

    background-size: 0.85em;
    background-position: center right 1em;

  }

  &.error {

    position: relative;
    color: $c-red;
    margin-bottom: 0;
    border: 1px solid $c-red;
    background-color: lighten($c-red, 38%);

  }

  &.warning {

      background: darken($c-orange, 3%);

  }

  &:disabled {

    opacity: 0.5;

  }
}

// placeholders
::-webkit-input-placeholder {

  color: lighten($c-text, 24%);

}

::-moz-placeholder {

  color: lighten($c-text, 24%);

}

:-ms-input-placeholder {

  color: lighten($c-text, 24%);

}

:-moz-placeholder {

  color: lighten($c-text, 24%);

}

.error::-webkit-input-placeholder {

  color: $c-red;

}

.error::-moz-placeholder {

  color: $c-red;

}

.error:-ms-input-placeholder {

  color: $c-red;

}

.error:-moz-placeholder {

  color: $c-red;

}
