@import 'components/global.scss';

.external {

  .message {

    h1 {

      color: $c-yellow;

    }
  }
}
