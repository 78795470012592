@import 'components/global.scss';

.multiselect {

  &.error .multiselect__control {

    color: $c-red;
    margin-bottom: 0;
    border: 1px solid $c-red;
    background-color: lighten($c-red, 38%);
    transition: none;

    &:hover {

      border: 1px solid $c-red !important;

    }
  }

  .multiselect__control {

    position: relative;
    border-radius: 0;
    color: $c-text;
    font-family: "Source Sans Pro", helvetica, arial, sans-serif;
    font-size: $scale;
    padding: $scale/2;
    border: 1px solid $c-border;
    margin: 0 0 $scale1;

    &:hover {

      border: 1px solid $c-border !important;

    }

    &:after {

      content: "";
      position: absolute;
      top: 50%;
      right: $scale;
      width: 0.85em;
      height: 0.5em;
      opacity: 0.35;
      pointer-events: none;
      transform: translateY(-50%);
      background-size: contain;
      background-image: url("./icons/ico-dropdown.svg");

    }
  }

  .multiselect__control--is-focused {

    border: 1px solid $c-border !important;

  }

  .multiselect__multi-value {

    color: white;
    background-color: $c-primary;

  }

  .multiselect__multi-value__label {

    color: white;

  }

  .multiselect__indicators {

    display: none;

  }
}
